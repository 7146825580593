import React, { forwardRef } from 'react'
import { Container, Row, Col } from '@kogk/common'

export default forwardRef(({ children, ...rest }, ref) => {
  return (
    <Container >
      <Row>
        <Col ref={ref} {...rest}>
          {children}
        </Col>
      </Row>
    </Container>
  )
})
