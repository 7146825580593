import React from 'react'
import { FancyShow } from '@kogk/common'
import GridLayout from '@cmp/site/GridLayout'

const SliceWrapper = ({
  shouldMove = true,
  children,
  fullBleed = false,
  ...props
}) => {
  return (
    <FancyShow shouldMove={shouldMove}>
      <article {...props}>
        {condWrap(!fullBleed, children, content => (
          <GridLayout>{content}</GridLayout>
        ))}
      </article>
    </FancyShow>
  )
}

export default SliceWrapper

const condWrap = (cond, content, wrapper) => {
  return cond ? wrapper(content) : content
}
